import Card, { type CardProps } from '@mui/material/Card';
import useTranslation from 'hooks/useTranslation';
import Error from 'next/error';
import { ForwardedRef, forwardRef, ReactElement } from 'react';

interface NotAllowedScreenProps {
    statusCode?: number,
    title?: string,
    CardProps?: CardProps,
    [key: string]: any
}

const NotAllowedScreen = ({ statusCode = 403, title, ...props }: NotAllowedScreenProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { t } = useTranslation();
    return (
        <>
            <Card
                elevation={0}
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
                ref={ref}
            >
                <Error
                    statusCode={statusCode}
                    title={title || t(`layout.error.network.${statusCode}`)}
                    {...props}
                />
            </Card>
        </>
    );
};

const NotAllowedScreenWithRef = forwardRef(NotAllowedScreen) as
    (p: NotAllowedScreenProps & { ref ?: ForwardedRef<HTMLDivElement> }) => ReactElement;

const NotAllowedScreenFunction = ({ ...props }: NotAllowedScreenProps = {}) => (
    <>
        <NotAllowedScreenWithRef {...props}/>
    </>
);

export default NotAllowedScreenWithRef;
export {
    NotAllowedScreenFunction
};

