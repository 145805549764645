import Backdrop, { type BackdropProps } from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { type ReactElement } from 'react';

interface LoadingScreenProps extends Partial<BackdropProps> {
    open?: boolean,
    children?: ReactElement | ReactElement[]
}

const LoadingScreen = ({ children, open = true, ...props }: LoadingScreenProps) => (
    <>
        <Backdrop
            open={open}
            transitionDuration={{ appear: 200, enter: 50, exit: 50 }}
            sx={{ 
                color: 'secondary.main',
                bgcolor: 'primary.main'
            }}
            {...props}
        >
            <>
                <CircularProgress color="inherit" />
                {children}
            </>
        </Backdrop>
    </>
);


export default LoadingScreen;
export type {
    LoadingScreenProps
};

