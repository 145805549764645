import Card, { type CardProps } from '@mui/material/Card';
import CardActionArea, { type CardActionAreaProps } from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FormCard, { type ActionDialogStateHandle } from 'components/form/FVFormCard';
import { type EstablishmentProps } from 'contexts/EstablishmentContext';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { useCallback, useRef, useState } from 'react';
import { AccountPasswordProps } from 'services/account';
import { useBoolean, useIsMounted, useIsomorphicLayoutEffect } from 'usehooks-ts';

interface EstablishmentCardProps extends CardProps {
    selected: boolean,
    handleClick: CardActionAreaProps['onClick'],
    establishment: EstablishmentProps,
    register?: unknown,
    loading?: boolean,
    onChangeBoolean?: unknown
}
const EstablishmentCard = ({ selected, establishment, handleClick, register, loading, onChangeBoolean, ...props }: EstablishmentCardProps) => {
    return (
        <>
            <Card elevation={2} {...props}>
                <CardActionArea value={establishment.id} onClick={handleClick}>
                    <CardContent sx={{ p:0 }}>
                        <Typography px={3} py={2} bgcolor={selected ? 'secondary.main' : ''} >
                            { establishment.name }
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </>
    );
};


interface ChangeFavoriteEstablishmentProps {
    data: EstablishmentProps[],
    onSubmit: (selected: number) => Promise<any>,
    dialog?: boolean,
    open?: boolean
}
const ChangeFavoriteEstablishment = ({ data, onSubmit, ...props }: ChangeFavoriteEstablishmentProps) => {
    const { t } = useTranslation();
    const isMounted = useIsMounted();
    const { createSnack } = useSnackbar();
    const [selected, setSelected] = useState<EstablishmentProps['id'] | undefined>( data.filter(({ favorite }) => favorite)[0]?.id as unknown as number || undefined);
    const handleClick = useCallback((id: number) => () => setSelected(id), []);

    const localRef = useRef<ActionDialogStateHandle<AccountPasswordProps>>(null);

    const submitLoading = useBoolean(false);
    const handleDataSubmit = useCallback((_data: unknown) => {
        submitLoading.setTrue();
        return ((selected) ? onSubmit(selected) : Promise.reject())
            .then(() => {
                if (localRef.current) {
                    localRef.current.update();
                }
            })
            .finally(() => {
                if (isMounted()) {
                    submitLoading.setFalse();
                }
            });
    }, [onSubmit, selected, submitLoading, isMounted]);

    useIsomorphicLayoutEffect(() => {
        if (localRef.current) {
            localRef.current.update();
        }
    }, [data]);

    return (
        <>
            <FormCard
                header={{ title: t('models.user.pages.account.favorite.title') }}
                fields={[ data.map(establishment => (
                    { 
                        label: t('models.user.data.user.establishment.favorite'), 
                        name: 'establishment_' + establishment.id, 
                        component: EstablishmentCard, 
                        selected: selected === establishment.id, 
                        establishment: establishment, 
                        value: (selected === establishment.id) as unknown as string, 
                        onClick: handleClick(establishment.id),
                        GridProps: { xs: (data.length > 0) ? 12 / data.length : 12 }
                    }
                ))]}
                onSubmit={handleDataSubmit}
                submitLoading={submitLoading.value}
                actions={{
                    submit: { 
                        text: t('layout.form.actions.save'), 
                        variant: 'contained',
                        size: 'large',
                        type: 'submit' 
                    }
                }}
                createSnack={createSnack}
                {...props}
                ref={localRef}
            />
        </>
    );
};


export default ChangeFavoriteEstablishment;
export {
    ChangeFavoriteEstablishment,
    EstablishmentCard
};
export type {
    ChangeFavoriteEstablishmentProps,
    EstablishmentCardProps
};

