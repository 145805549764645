const isEmpty = (obj: object) => {for (const i in obj) {return false;} return true; };


type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
type Diff<T, K> = Omit<T, keyof K>;

/**
 * fonction de comparaison des parametres de composants pour les formulaires imbriqués,
 * lorsque plusieurs composants utilisent le même objet data mais chacun n'utilise qu'une partie des clés de cet objet.
 *
 * ! Eviter d'utiliser autant que possible !
 *
 * @return false si doit update, true sinon.
 *
 * @example
 *  type FormDataShapeProps = Pick<DataProps, 'key1' | ... | 'keyN'>;
 *  interface FormComponentProps extends FormCardProps<FormDataShapeProps> {...};
 *  const FormComponent = ({ data, ...props }: FormComponentProps) => {...};
 *  const defaultFormShape: Readonly<FormDataShapeProps> = {...} //permet d'utiliser Object.keys en dessous et d'instancier rapidement avec les fonctionnalités d'autocomplétion
 *  const MemoizedFormComponent = memo(FormComponent, compareData<FormDataShapeProps>(Object.keys(defaultFormShape) as Array<keyof FormDataShapeProps>));
 *  export default MemoizedFormComponent;
 */
const compareData = <T extends { [key: string]: unknown }, TDataShape extends { data?: T } = any>(...compareKeys: Array<keyof T>) => {
    const compare = (prev: TDataShape, next: TDataShape) => {
        const { data: prevData, ...prevProps } = prev;
        const { data: nextData, ...nextProps } = next;

        //regarde d'abord sur data pour vérif si il y a des changements, si oui pas besoin de check les autres
        const dataIsEqual = compareKeys.reduce((a, c) => {
            return a && ((nextData ? nextData?.[c] : undefined) == (prevData ? prevData?.[c] : undefined));
        }, true);
        if (!dataIsEqual) { return false; }

        //vérifie les autres propriétés
        const otherPropsEqual = (Object.keys(prevProps)).reduce((a, key) => {
            return a && prevProps[key as keyof Omit<TDataShape, 'data'>] == nextProps[key as keyof Omit<TDataShape, 'data'>];
        }, true);
        return otherPropsEqual;

    };
    return compare;
};

export {
    compareData,
    isEmpty
};
export type {
    Omit,
    Diff
};

