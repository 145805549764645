import { ChangeFavoriteEstablishment } from 'components/account/FavoriteEstablishment';
import useApi from 'hooks/useApi';
import useSnackbar from 'hooks/useSnackbar';
import { useRouter } from 'next/router';
import { createContext, Fragment, type ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { API_URLS } from 'services/api';
import { type UrlObject } from 'url';
import { useLocalStorage } from 'usehooks-ts';
import { type RightsProps } from './AuthContext';

interface EstablishmentProps {
    id: number,
    name: string,
    show: boolean,
    isWelogin: boolean,
    favorite?: boolean,
    right?: RightsProps
}

interface FavoriteStateProps {
    needed: boolean,
    loading: boolean,
    id?: EstablishmentProps['id'] 
}
interface SetSelectedOptionsProps {
    redirectUri?: string | UrlObject,
    preserveHistory?: boolean
}

interface EstablishmentContextProps {
    establishments: EstablishmentProps[],
    selected: EstablishmentProps | undefined,
    setSelected: (as: EstablishmentProps['id'], options?: SetSelectedOptionsProps) => Promise<unknown>,
    favoriteState?: FavoriteStateProps,
    setFavorite: (id: EstablishmentProps['id'], redirectUri?: string, preserveHistory?: boolean) => Promise<unknown>
}
const defaultEstablishmentContext: EstablishmentContextProps = {
    establishments: [],
    setSelected: (_as: number) => Promise.reject(null),
    selected: { id: 0, name: '', show: true, isWelogin: false },
    favoriteState: undefined,
    setFavorite: (_id: number) => Promise.reject(null)
};

const EstablishmentContext = createContext<EstablishmentContextProps>(defaultEstablishmentContext);

interface EstablishmentProviderProps {
    children: ReactElement | ReactElement[]
}
const EstablishmentProvider = ({ children }: EstablishmentProviderProps) => {
    const { establishments, getApi } = useApi();
    const { createSnack } = useSnackbar();
    const router = useRouter();
    const [favoriteState, setFavoriteState] = useState<FavoriteStateProps>({
        needed: false,
        loading: false,
        id: (establishments && establishments.length) && (establishments.find(({ favorite }) => favorite)?.id || establishments[0]?.id) || undefined
    });

    const [ selectedId, setSelectedId ] = useLocalStorage<number | undefined>('establishmentId', favoriteState.id);

    const handleChangeSelected = useCallback((as: EstablishmentProps['id'], { redirectUri = '/', preserveHistory = false }: SetSelectedOptionsProps = {}) => {
        return new Promise((resolve, reject) => {
            if (establishments && establishments.map(({ id }) => id).includes(as)) {
                resolve(setSelectedId(as));
            } else {
                reject('establishment.notFound');
            }
        }).finally(() => {
            return preserveHistory ? router.push(redirectUri) : router.replace(redirectUri);
        });
    }, [establishments]);

    useEffect(() => {
        if (establishments && establishments.length > 1 && establishments.filter(({ favorite }) => favorite).length !== 1) {
            setFavoriteState(({ ...state }) => ({ ...state, needed: true }));
        } else {
            const id = establishments && (establishments.filter(({ favorite }) => favorite)[0]?.id || establishments[0]?.id) || undefined;
            setFavoriteState(({ ...state }) => ({ ...state, id }));
        }
    }, [establishments]);

    useEffect(() => {
        if (selectedId == undefined) {
            setSelectedId(favoriteState?.id || establishments && establishments[0]?.id);
        }
    }, [establishments, favoriteState?.id, selectedId]);

    const handleSubmitFavorite = useCallback((data: any, redirectUri = '/', preserveHistory = false) => {
        setFavoriteState(({ ...state }) => ({ ...state, loading: true }));
        
        return getApi({ url: `${API_URLS.ESTABLISHMENT_INDEX}/${data}/favorite` })
            .then(() => {
                handleChangeSelected(data, { redirectUri, preserveHistory });
                setFavoriteState(({ ...state }) => ({ ...state, needed: false, loading: false, id: data }));
                createSnack({
                    message: 'success',
                    translateKey: 'models.user.pages.account.favorite'
                });
            })
            .catch(() => createSnack({
                message: 'error',
                severity: 'error',
                translateKey: 'models.user.pages.account.favorite'
            }))
            .finally(() => setFavoriteState(({ ...state }) => ({ ...state, loading: false })));
    }, [createSnack, getApi, handleChangeSelected]);
    
    const contextValue = useMemo(() => ({
        get establishments() {return establishments || [];},
        get selected() { return establishments?.find(({ id }: { id:number }) => id === selectedId);},
        setSelected: handleChangeSelected,
        setFavorite: handleSubmitFavorite,
        favoriteState
    }), [establishments, selectedId, handleChangeSelected, handleSubmitFavorite, favoriteState]);

    return (
        <EstablishmentContext.Provider value={contextValue}>
            {favoriteState.needed ? <ChangeFavoriteEstablishment
                dialog={true}
                open={favoriteState.needed}
                data={establishments || []}
                onSubmit={handleSubmitFavorite}
            /> : <></>}
            <Fragment key={selectedId}>
                {children}
            </Fragment>
        </EstablishmentContext.Provider>
    );
};

export default EstablishmentProvider;
export {
    EstablishmentProvider,
    EstablishmentContext
};
export type {
    EstablishmentProviderProps,
    EstablishmentContextProps,
    EstablishmentProps,
    FavoriteStateProps
};

