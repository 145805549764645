import { EstablishmentContext, type EstablishmentContextProps } from 'contexts/EstablishmentContext';
import { useContext } from 'react';



const useEstablishment = () => useContext(EstablishmentContext) as EstablishmentContextProps;

export default useEstablishment;
export {
    useEstablishment
};

