import { NotAllowedScreenFunction } from 'components/layout/NotAllowedScreen';
import LoadingScreen from 'components/utils/LoadingScreen';
import { type RightsProps } from 'contexts/AuthContext';
import { useRouter } from 'next/router';
import { type ReactElement, useCallback, useEffect } from 'react';
import { useBoolean, useIsMounted } from 'usehooks-ts';
import { isEmpty } from '../utils/index';
import useApi from './useApi';
import useEstablishment from './useEstablishments';

const loginRedirectUri = '/login';
const dashboardFVRedirectUri = '/establishments';
const dashboardProRedirectUri = '/establishment';

interface CallbackProps {
    name?: string;
    rights?: RightsProps;
}

const checkFn = ({ name, rights }: CallbackProps) => {
    if (!name) return true;
    if (!rights || isEmpty(rights)) return false;
    let res = false;
    const [niv0, niv1] = name?.split('.') || [];
    if (!niv0 || !rights[niv0]) return false;
    res = rights[niv0][niv1 || 'index'] || false;
    return res;
};

interface ProtectProps {
    name?: string;
    rights?: RightsProps;
    callback?: (props: CallbackProps) => any;
}


const isAllowed = ({ name, rights, callback = checkFn }: ProtectProps) =>
    callback({ name, rights });


const useElementProtect = (name?: ProtectProps['name']) => {
    const { right } = useApi();
    const { selected } = useEstablishment();

    const protect = useCallback(
        (el: ReactElement) => {
            return isAllowed({ name, rights: { ...right, ...selected?.right } })
                ? el
                : null;
        },
        [name, right, selected]);

    return {
        protect,
        get isAllowed(){return isAllowed({ name, rights: { ...right, ...selected?.right } })} 
    };
};

/**
 * redirect the user to the login page if not authentified
 * @returns el if allowed Fragment (= <></>) if not
 */
 interface AuthPageProtectProps {
    needAuth?: boolean;
    needUnauth?: boolean;
}
const useApiPageProtect = (authPageProtectProps: AuthPageProtectProps) => {
    const router = useRouter();
    const { authenticated, loading, establishments } = useApi();
    const tempLoading = useBoolean(true);
    const isMounted = useIsMounted();

    useEffect(() => {
        if (isMounted()) {
            if (loading) {
                tempLoading.setTrue();
            } else {
                if (authPageProtectProps && authPageProtectProps.needAuth && !authenticated) {
                    router.replace(loginRedirectUri);
                } else if (
                    authPageProtectProps &&
                    authPageProtectProps.needUnauth &&
                    authenticated
                ) {
                    router.replace('/');
                } else {
                    tempLoading.setFalse();
                }
            }
        }
    //Si ajout de authPageProtectProps en dépendance, appel en boucle : pas besoin de le mettre en paramètre cart non changeant
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, authenticated, isMounted]);

    const protect = useCallback((el: ReactElement) => {
        if (tempLoading.value) {
            return LoadingScreen({ open: true });
        } else {
            return el;
        }
    }, [tempLoading])


    return {
        protect 
    };
};

interface RightPageProtectProps {
    needRight?: string;
}
const useRightPageProtect = (rightPageProtectProps?: RightPageProtectProps) => {
    const router = useRouter();
    const { authenticated, right, loading } = useApi();
    const { selected: selectedEstablishment } = useEstablishment();
    const tempLoading = useBoolean(true);
    const allowed = useBoolean(false);
    const isMounted = useIsMounted();

    useEffect(() => {
        if (isMounted()) {
            if (loading) {
                tempLoading.setTrue();
            } else {
                if (rightPageProtectProps && !authenticated) {
                    router.replace(loginRedirectUri);
                } else if (rightPageProtectProps) {
                    allowed.setValue(
                        isAllowed({
                            name: rightPageProtectProps.needRight,
                            rights: { ...right, ...selectedEstablishment?.right }
                        })
                    );
                    tempLoading.setFalse();
                } else {
                    allowed.setTrue();
                    tempLoading.setFalse();
                }
            }
        }
    }, [authenticated, loading, router, right, selectedEstablishment?.right, selectedEstablishment, rightPageProtectProps, isMounted]);

    const protect = useCallback((el: ReactElement) => {
        if (tempLoading.value) {
            return LoadingScreen({ open: true });
        } else {
            if (!allowed.value) {
                return NotAllowedScreenFunction({statusCode : 403});
            }
            return el;
        }
    }, [allowed, tempLoading])

    return {
        protect
    };
};

export default useApiPageProtect;
export {
    useApiPageProtect,
    useRightPageProtect,
    useElementProtect,
    isAllowed,
    loginRedirectUri,
    dashboardFVRedirectUri,
    dashboardProRedirectUri
};
export type { CallbackProps, ProtectProps, RightPageProtectProps, AuthPageProtectProps };

